import { Play as IconPlay } from "phosphor-react"
import PropTypes from "prop-types"
import { ComponentProps, useState } from "react"
import Image from "../../resolvers/image"

interface Props {
  embedId: string
  thumbnail?: ComponentProps<typeof Image>
}

const YoutubeEmbed = ({ embedId, thumbnail }: Props) => {
  const [loadVideo, setLoadVideo] = useState(false)

  const onPlayButtonClick = () => {
    setLoadVideo(true)
  }

  const imageComponent = thumbnail ? (
    <div onClick={onPlayButtonClick} className="grid cursor-pointer">
      <div
        style={{
          gridArea: "1/1",
        }}
      >
        <Image {...thumbnail} className="aspect-video w-full overflow-hidden" />
      </div>
      <div
        className="relative grid place-items-center"
        style={{
          gridArea: "1/1",
        }}
      >
        <IconPlay
          weight="fill"
          size={48}
          onClick={onPlayButtonClick}
          className="text-gray-300"
        />
      </div>
    </div>
  ) : undefined

  const videoUrl = loadVideo
    ? "https://www.youtube-nocookie.com/embed/" + embedId + "?autoplay=1"
    : "https://www.youtube-nocookie.com/embed/" + embedId

  return (
    <div>
      {!thumbnail || loadVideo ? (
        <iframe
          src={videoUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="aspect-video w-full"
        />
      ) : (
        imageComponent
      )}
    </div>
  )
}

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
}

export default YoutubeEmbed
